@mixin button-base($_button-border: 0 none) {
  display: flex;
  width: auto;
  font-family: inherit;
  font-size: 100%;
  white-space: normal;
  word-spacing: normal;
  cursor: pointer;
  border: $_button-border;
  margin: 0;
  padding: 0 auto;
  background-image: none;
  border-radius: $button-border-radius;

  @include respond-to-down(md) {
    max-width: $button-mobile-max-width;
  }

  > span {
    white-space: normal;
    word-spacing: normal;
    width: 100%;
    overflow: hidden;
  }

  &:hover,
  &:active,
  &:focus,
  &:visited {
    text-decoration: none;
  }

  &:active {
    outline: none;
  }

  &::-moz-focus-inner {
    padding: 0;
    border: 0 none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: $button-disabled-opacity;
    cursor: not-allowed; // [11]
    pointer-events: none; // [11]
  }
}

@mixin button-reset() {
  padding: 0;
  margin: 0;
  font-size: 100%;
  width: auto;
  border: 0 none;
  color: $black;
  background: none;

  &:hover,
  &:active,
  &:focus {
    border: 0 none;
    color: $black;
    background: none;
  }
}

@mixin icon-button($icon-name: 'arrow-left', $btn-icon-size: 16px) {
  @include button-base();
  @include ms-icon($icon-name, $btn-icon-size);
  border-radius: 0;
  border: 0 none;
  box-sizing: border-box;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;


  span {
    @include sr-only();
  }
}
