$button-styles-override: true;

// Buttons
$button-cheer-bg: map_get($theme-colors, 'primary');
$button-cheer-color: $white;
$button-cheer-hover-color: $white;
$button-cheer-hover-bg: darken((map_get($theme-colors, 'primary')), 10%);
$button-cheer-text-transform: uppercase;
$button-cheer-padding-x: 36px;
$button-cheer-padding-y: 14px;

$button-cheer-inverted-bg: map_get($theme-colors, 'dark');
$button-cheer-inverted-color: $white;
$button-cheer-inverted-hover-color: $white;
$button-cheer-inverted-hover-bg: map-get($colors, 'light-grey');
$button-cheer-inverted-padding-x: 36px;
$button-cheer-inverted-padding-y: 14px;
$button-cheer-inverted-text-transform: uppercase;

$button-whisper-bg: transparent;
$button-whisper-hover-bg: transparent;
$button-whisper-color: map_get($theme-colors, 'dark');
$button-whisper-hover-color: map_get($colors, 'light-grey');
$button-whisper-letter-spacing: -0.02em;
$button-whisper-icon-spacing-x: 16px;
$button-whisper-icon: 'arrow-right';
$button-whisper-text-transform: none;
$button-whisper-padding-x: 5px;
$button-whisper-padding-y: 0;

$button-whistle-bg: transparent;
$button-whistle-hover-bg: transparent;
$button-whistle-color: map-get($theme-colors, 'dark');
$button-whistle-underline-width: 0;
$button-whistle-underline-color: transparent;
$button-whistle-hover-color: map-get($colors, 'light-grey');
$button-whistle-padding-x: 0;
$button-whistle-padding-y: 0;
$button-whistle-text-transform: uppercase;

$button-murmur-border: 2px solid $black;
$button-murmur-color: $black;
$button-murmur-hover-color: map-get($colors, 'light-grey');
$button-murmur-text-transform: uppercase;
$button-murmur-padding-x: 15px;
