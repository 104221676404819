.eupopup-button {
  display: inline-block;
  vertical-align: middle;
  margin-top: $eupopup-buttons-spacing-y;
}

.eupopup-button_1 {
  @extend %button-base;
  @extend %button-#{$eupopup-button-style};
}

.eupopup-button_2 {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

.eupopup-button_1 + .eupopup-button_2 {
  margin-left: spacers(3);
}