/*
 * eupopup-container styles to control position
 *
 */

$eupopup-container-padding: spacers(3) spacers(3) * 1.15 !default;
$eupopup-container-bg: theme-color(light) !default;
$eupopup-container-color: theme-color(dark) !default;
$eupopup-container-inline-max-width: 500px !default;
$eupopup-container-shadow: 0 0 3px rgba(0,0,0,0.12), 0 0 2px rgba(0,0,0,0.24) !default;
$eupopup-container-font-size: $font-size-sm !default;
$eupopup-head-font-weight: $font-weight-bold !default;
$eupopup-head-spacing-y: spacers(2) !default;
$eupopup-buttons-spacing-y: spacers(2) !default;
$eupopup-button-style: 'whistle' !default;
$eupopup-close-icon: 'x' !default;
$eupopup-close-icon-size: 36px !default;
$eupopup-close-icon-color: theme-color(dark) !default;
$eupopup-close-icon-bg: theme-color(light) !default;
$eupopup-z-index: z('popover') + 1 !default;
$eupopup-container-margin: spacers(2) !default;
$eupopup-container-margin-lg: spacers(3) !default;

.eupopup-container {
  position: relative;
  padding: $eupopup-container-padding;
  background: $eupopup-container-bg;
  color: $eupopup-container-color;
  font-size: $eupopup-container-font-size;
  z-index: $eupopup-z-index;
  box-shadow: $eupopup-container-shadow;
  margin: $eupopup-container-margin;

  @include respond-to-up(lg) {
    margin: $eupopup-container-margin-lg;
  }

  &-top {
    @include absolute(0, 0, auto, 0);
  }

  &-fixedtop {
    @include fixed(0, 0, auto, 0);
  }

  &-top,
  &-fixedtop {
    animation: u-animation-slide-down-small $transition-duration $transition-timing-ease-in;
  }

  // By Default bottom is always fixed
  &-bottom {
    @include fixed(auto, 0, 0, 0);
  }

  // By Default bottom is always fixed
  &-bottomleft {
    @include fixed(auto, auto, 0, 0);
    max-width: $eupopup-container-inline-max-width;
  }
  // By Default bottom is always fixed
  &-bottomright {
    @include fixed(auto, 0, 0, auto);
    max-width: $eupopup-container-inline-max-width;
  }

  &-bottom,
  &-bottomleft,
  &-bottomright {
    animation: u-animation-slide-up-small $transition-duration $transition-timing-ease-in;
  }

  // Inline Block
  &-block {
    display: block;
  }
}

.clearfix {
  @include clearfix;
}

/*
body.eupopup-bottom {

}

body.eupopup-top {

}*/