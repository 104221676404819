.eupopup-color-inverse {
  &.eupopup-container {
    background: $eupopup-container-color;
    color: $eupopup-container-bg;

    .eupopup-closebutton {
      background: $eupopup-container-color;
      color: rgba(0, 0, 0, 0);

      &:before {
        color: $eupopup-container-bg;
      }
    }

    .eupopup-button {
      color: $eupopup-container-bg !important;
    }
  }
}