@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.eupopup-button_1 {
  display: flex;
  width: auto;
  font-family: inherit;
  font-size: 100%;
  white-space: normal;
  word-spacing: normal;
  cursor: pointer;
  border: 0 none;
  margin: 0;
  padding: 0 auto;
  background-image: none;
  border-radius: 0;
  padding: 4px 34px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .eupopup-button_1 {
    max-width: 222px;
  }
}

.eupopup-button_1 > span {
  white-space: normal;
  word-spacing: normal;
  width: 100%;
  overflow: hidden;
}

.eupopup-button_1:hover, .eupopup-button_1:active, .eupopup-button_1:focus, .eupopup-button_1:visited {
  text-decoration: none;
}

.eupopup-button_1:active {
  outline: none;
}

.eupopup-button_1::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}

.disabled.eupopup-button_1, .eupopup-button_1[disabled],
fieldset[disabled] .eupopup-button_1 {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.eupopup-button_1 {
  position: relative;
  text-decoration: none;
  background: transparent;
  color: #000;
  text-transform: uppercase;
  font-weight: 800;
  padding: 0 0;
}

.eupopup-button_1:focus, .eupopup-button_1:hover {
  color: #97999C;
  background: transparent;
}

.eupopup-button_1:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

/*
 * eupopup-container styles to control position
 *
 */
.eupopup-container {
  position: relative;
  padding: 1rem 1.15rem;
  background: #eee;
  color: #000;
  font-size: 0.875rem;
  z-index: 1061;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.24);
  margin: 0.5rem;
}

@media screen and (min-width: 992px) {
  .eupopup-container {
    margin: 1rem;
  }
}

.eupopup-container-top {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
}

.eupopup-container-fixedtop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
}

.eupopup-container-top, .eupopup-container-fixedtop {
  animation: u-animation-slide-down-small 0.375s cubic-bezier(0, 0, 0.2, 1);
}

.eupopup-container-bottom {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

.eupopup-container-bottomleft {
  position: fixed;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  max-width: 500px;
}

.eupopup-container-bottomright {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  max-width: 500px;
}

.eupopup-container-bottom, .eupopup-container-bottomleft, .eupopup-container-bottomright {
  animation: u-animation-slide-up-small 0.375s cubic-bezier(0, 0, 0.2, 1);
}

.eupopup-container-block {
  display: block;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

/*
body.eupopup-bottom {

}

body.eupopup-top {

}*/
.eupopup-head {
  font-weight: 800;
  margin-bottom: 0.5rem;
}

/*.eupopup-body {

}*/
.eupopup-buttons {
  margin-bottom: 0;
}

.eupopup-button {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0.5rem;
}

.eupopup-button_2 {
  color: #b0aa00;
  text-decoration: none;
}

.eupopup-button_2:hover, .eupopup-button_2:focus {
  color: #646000;
  text-decoration: underline;
}

.eupopup-button_1 + .eupopup-button_2 {
  margin-left: 1rem;
}

.eupopup-closebutton {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0);
  background: #eee;
  z-index: 2;
}

.eupopup-closebutton:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.eupopup-closebutton:hover, .eupopup-closebutton:focus {
  color: rgba(0, 0, 0, 0);
}

.eupopup-closebutton:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 36px;
  color: #000;
}

/*.eupopup-style-compact {

}*/
.eupopup-color-inverse.eupopup-container {
  background: #000;
  color: #eee;
}

.eupopup-color-inverse.eupopup-container .eupopup-closebutton {
  background: #000;
  color: rgba(0, 0, 0, 0);
}

.eupopup-color-inverse.eupopup-container .eupopup-closebutton:before {
  color: #eee;
}

.eupopup-color-inverse.eupopup-container .eupopup-button {
  color: #eee !important;
}
