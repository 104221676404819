.eupopup-closebutton {
  @include absolute(0, 0);
  @include size($eupopup-close-icon-size);
  @include ms-icon($eupopup-close-icon);
  padding: spacers(2);
  color: rgba(0, 0, 0, 0);
  background: $eupopup-close-icon-bg;
  z-index: z('above');

  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0);
  }

  &:before {
    @include absolute(0, 0);
    @include size(100%);
    text-align: center;
    line-height: $eupopup-close-icon-size;
    color: $eupopup-close-icon-color;
  }
}